'use client';
import { defaultImageUrl } from '@/constants/inventory';
import { GridInventory } from '@lamesarv-sdk/inventory';
import { IBasicInventory, IComponentGridInventory, IMetaData, InventoryField } from '@lamesarv-sdk/types';

import { InventoryItem } from './InventoryItem';

interface InventoryGridProps extends IComponentGridInventory {
  metadata: IMetaData;
}

export const InventoryGrid = (props: InventoryGridProps) => (
  <GridInventory
    {...props}
    sort={`${InventoryField.order}:asc`}
    defaultImageUrl={defaultImageUrl}
    CustomInventoryItem={(item: IBasicInventory) => <InventoryItem item={item} />}
  />
);
