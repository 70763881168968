'use client';

import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import { getInventoryType, InventoryType, InventoryTypeMap } from '@/types/inventory';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFacetCode } from '@lamesarv-sdk/tools';
import { IMetaData } from '@lamesarv-sdk/types';

interface IHeroFormProps {
  metadata: IMetaData;
}

export const HeroForm = (props: IHeroFormProps) => {
  const [filterBody, setFilterBody] = useState<string>('');
  const [filterCustom, setFilterCustom] = useState<string>('');
  const [filterInventoryType, setFilterInventoryType] = useState<string>('');
  const [filterPriceMin, setFilterPriceMin] = useState<number>(0);
  const [filterPriceMax, setFilterPriceMax] = useState<number>(0);
  const [filterPriceError, setFilterPriceError] = useState<boolean>(false);

  const filterFormRef = useRef(null);

  const handleChangeBody = (e: SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    const value = target.value;
    const filters = target.options[target.selectedIndex]?.dataset?.filters?.trim() || '';

    setFilterBody(value);
    setFilterCustom(filters);
  };

  const handleChangeInventoryType = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    setFilterInventoryType(value);
  };

  const handleChangePriceMin = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const valueNumber = value ? Number(value) : 0;

    setFilterPriceError(!!valueNumber && !!filterPriceMax && valueNumber >= filterPriceMax);
    setFilterPriceMin(valueNumber);
  };

  const handleChangePriceMax = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const valueNumber = value ? Number(value) : 0;

    setFilterPriceError(!!valueNumber && !!filterPriceMin && valueNumber <= filterPriceMin);
    setFilterPriceMax(valueNumber);
  };

  const searchUrl = useMemo(() => {
    const urlArr = [];

    if (filterCustom) {
      urlArr.push(filterCustom);
    } else if (filterBody) {
      urlArr.push(`body=${filterBody}`);
    }

    if (filterInventoryType) {
      urlArr.push(`inventoryType=${filterInventoryType}`);
    }

    if (!filterPriceError && (filterPriceMin || filterPriceMax)) {
      urlArr.push(`price=${filterPriceMin}:${filterPriceMax}`);
    }

    if (urlArr.length) {
      return `/search/?${urlArr.join('&')}`;
    } else {
      return '/search';
    }
  }, [filterBody, filterCustom, filterInventoryType, filterPriceMin, filterPriceMax, filterPriceError]);

  useEffect(() => {
    if (filterFormRef.current) {
      setTimeout(() => {
        (filterFormRef.current as unknown as HTMLFormElement).reset();
      }, 500);
    }
  }, []);

  return (
    <div className="xs:w-96 -mb-40 shadow-md mx-auto -translate-y-1/2 md:mb-0 md:absolute md:left-20 md:top-1/2 bg-white p-4 rounded-sm shadows-md">
      <div className="text-xl font-bold">Find Your Perfect RV</div>
      <form className="" ref={filterFormRef}>
        <div className="flex flex-row items-center p-2 border border-blue-800 rounded-sm mt-4">
          <select className="w-full border-none focus:outline-none focus:ring-0" onChange={handleChangeBody}>
            <option value="">Select an RV Type</option>
            {props.metadata.pagesBodyData?.map((item, index) => {
              return (
                <option key={index} value={getFacetCode(item.title)} data-filters={item.filters}>
                  {item.displayText || item.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col 2xs:flex-row gap-3 p-4 border border-blue-800 rounded-sm mt-4">
          <div className="flex flex-row gap-2 items-center grow">
            <label className="flex flex-row items-center gap-2">
              <input
                type="radio"
                name="inventoryType"
                value=""
                className="focus:outline-none focus:ring-white"
                defaultChecked
                onChange={handleChangeInventoryType}
              />
              <span className="leading-0">All RVs</span>
            </label>
          </div>
          <div className="border-t 2xs:border-t-0 2xs:border-r"></div>
          <div className="flex flex-row gap-2 items-center grow">
            <label className="flex flex-row items-center gap-2">
              <input
                type="radio"
                name="inventoryType"
                value="new"
                className="focus:outline-none focus:ring-white"
                onChange={handleChangeInventoryType}
              />
              <span className="leading-0">{getInventoryType(InventoryTypeMap[InventoryType.new])}</span>
            </label>
          </div>
          <div className="border-t 2xs:border-t-0 2xs:border-r"></div>
          <div className="flex flex-row gap-2 items-center grow">
            <label className="flex flex-row items-center gap-2">
              <input
                type="radio"
                name="inventoryType"
                value="used"
                className="focus:outline-none focus:ring-white"
                onChange={handleChangeInventoryType}
              />
              <span className="leading-0">{getInventoryType(InventoryTypeMap[InventoryType.used])}</span>
            </label>
          </div>
        </div>
        <div
          className={`flex flex-col 2xs:flex-row gap-2 p-4 border rounded-sm mt-4 ${
            filterPriceError ? 'border-red-400' : 'border-blue-800'
          }`}
        >
          <div className="flex flex-row flex-1 items-center">
            <FontAwesomeIcon icon={faDollarSign} className="w-4 h-4 text-white rounded-full bg-blue-800 p-0.5" />
            <div className="flex flex-1">
              <input
                type="number"
                placeholder="Min Price"
                className="ml-2 p-0 border-none focus:outline-none focus:ring-0 text-left w-full"
                step={1}
                min={0}
                value={filterPriceMin || ''}
                onChange={handleChangePriceMin}
              />
            </div>
          </div>
          <div className="border-t 2xs:border-t-0 2xs:border-r"></div>
          <div className="flex flex-row flex-1 items-center">
            <FontAwesomeIcon icon={faDollarSign} className="w-4 h-4 text-white rounded-full bg-blue-800 p-0.5" />
            <div className="flex flex-1">
              <input
                type="number"
                placeholder="Max Price"
                className="ml-2 p-0 border-none focus:outline-none focus:ring-0 text-left w-full"
                step={1}
                min={0}
                value={filterPriceMax || ''}
                onChange={handleChangePriceMax}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <a href={searchUrl} className="block w-full text-center bg-black text-white p-4 rounded-sm uppercase">
            Search our RVs
          </a>
        </div>
      </form>
    </div>
  );
};
